import './Cierre.css';

function Cierre() {
    return (
        <section id="cierre" className='section-container'>
            <h3>¿Listo para el futuro?</h3>
            <hr/>
            <p>Maracuyá Atelier S.A. de C.V. 2022 ©</p>
        </section>
    )
}

export default Cierre;